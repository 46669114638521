import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function Theatres() {
    const API_URL = 'https://api.gametheory.cafe'
    const [theatres, setTheatres] = useState([])

    useEffect(() => {
        const getTheatres = () => {
            axios.get(`${API_URL}/gtadmin/theatredetails`)
                .then(res => {
                    setTheatres(res.data)
                })
                .catch(err => console.log(err))
        }
        getTheatres()
    }, [])
    if (theatres.length === 0) {
        return <div> Loading.. </div>
    } return (
        <table>
            <thead>
                <tr>
                    <th>Theatre id</th>
                    <th>Name</th>
                    <th>Max Capacity</th>
                    <th>Pricing (1 hour)</th>
                </tr>
            </thead>
            <tbody>
                {
                    theatres.map((theatre, index) => (
                        <tr key={index}>
                            <td><Link to={`/theatres/${theatre.thid}`}>{theatre.thid}</Link></td>
                            <td>{theatre.name}</td>
                            <td>{theatre.max_capacity}</td>
                            <td>{theatre.pricing[0].base_price}</td>

                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}

export default Theatres