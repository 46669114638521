import React from 'react'

function GametheoryLogo() {
    return (
        <svg width="78" height="72" viewBox="0 0 78 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_0_1)">
                <path d="M51.7953 4H26.5984L14 26L26.5984 48H51.2703L64 26.3976L51.7953 4Z" fill="#F9F6F1" />
            </g>
            <path d="M44.828 22.8678L54.0716 38.8135L52.263 41.9333L50.4852 45H50.4488L37.6318 22.9669L37.5663 22.8538L30.0005 22.8561H30L33.9746 16H56.0187L60 22.8678H44.828Z" fill="black" />
            <path d="M61.2988 26.2902L56.0278 35.4166L55.6681 36.0397L49.7212 25.7781H61.0031L61.2988 26.2902Z" fill="#E62E28" />
            <path d="M36.0964 25.7782L47.3228 45.2159H28.3686L17.2598 25.9787L28.3686 6.74598H50.5873L54.558 13.6197L32.4343 13.6147L30.7528 16.5259L25.2921 25.9809L32.3848 38.2621H35.6449L28.4433 25.7782H36.0964Z" fill="black" />
            <defs>
                <filter id="filter0_d_0_1" x="0.2" y="0.2" width="77.6" height="71.6" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="10" />
                    <feGaussianBlur stdDeviation="6.9" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.776471 0 0 0 0 0.631373 0 0 0 0 0.470588 0 0 0 0.4 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export default GametheoryLogo