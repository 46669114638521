import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
function Cakes() {
    const [cakes, setCakes] = useState([])
    const API_URL = 'https://api.gametheory.cafe'
    useEffect(() => {
        const getCakes = () => {
            axios.get(`${API_URL}/gtadmin/cakes`)
                .then(res => {
                    setCakes(res.data)
                })
                .catch(err => console.log(err))
        }
        getCakes()
    }, [])
    return (
        <table>
            <thead>
                <tr>
                    <th>Cake id</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                {
                    cakes.map((cake, index) => (
                        <tr key={index}>
                            <td><Link to={`/cakes/${cake.cake_id}`}>{cake.cake_id}</Link></td>
                            <td>{cake.name}</td>
                            <td>{cake.price}</td>
                            <td>{cake.description}</td>
                        </tr>
                    ))
                }
            </tbody>

        </table>
    )
}

export default Cakes