import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Layout from './components/Layout';
import GTRoutes from './components/GTRoutes';
import './styles/table.css'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Layout>
          <GTRoutes />
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;
