import { useEffect, useState } from 'react'
import axios from 'axios'
import '../styles/create-order.css'
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from 'react-datepicker'

const CreateOrder = () => {
    const [theatreDetails, setTheatreDetails] = useState([])
    const [message, setMessage] = useState('')
    const [decorations, setDecorations] = useState([])
    const [cakes, setCakes] = useState([])
    const [slots, setSlots] = useState([])
    const [bookingData, setbookingData] = useState({
        thid: "gt_mini",
        date: '',
        duration: 3,
        starttime: "",
        endtime: "",
        customer: {
            name: "",
            phone: "",
            email: ""
        },
        guests: 2,
        dec_id: "",
        cake_id: ""
    })
    const API_URL = 'https://api.gametheory.cafe'
    const formatDate = (dateValue, formatTo) => {
        if (formatTo === 'dd-mm-yyyy') {
            const [day, month, year] = dateValue.split('-');
            return `${day}-${month}-${year}`;
        }
        if (formatTo === 'yyyy-mm-dd') {
            const [day, month, year] = dateValue.split('-');
            return `${year}-${month}-${day}`;
        }
        if (formatTo === 'dateObject') {
            const day = dateValue.getDate();
            const month = dateValue.getMonth() + 1;
            const year = dateValue.getFullYear();
            return `${year}-${month}-${day}`;
        }
    };

    const getTheatreDetails = () => {
        axios.get(`${API_URL}/gtadmin/theatredetails`)
            .then(res => {
                setTheatreDetails(res.data)
            })
            .catch(err => console.log(err))
    }

    const getAvailableSlots = (date) => {
        setSlots([])
        setbookingData((prevVal) => ({ ...prevVal, slot_name: '' }))
        axios.get(`${API_URL}/gtbookings/booking?date=${date}&thid=${bookingData.thid}&duration=${bookingData.duration}`)
            .then(res => {
                setSlots(res.data)
            })
            .catch(err => console.log(err))
    }

    const getDecorations = () => {
        axios.get(`${API_URL}/gtadmin/decorations`)
            .then(res => {
                setDecorations(res.data)
            })
            .catch(err => console.log(err))
    }

    const getCakes = () => {
        axios.get(`${API_URL}/gtadmin/cakes`)
            .then(res => {
                setCakes(res.data)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        if (bookingData.date) getAvailableSlots(formatDate(bookingData.date, 'yyyy-mm-dd'))
        setbookingData((prevVal) => ({ ...prevVal, starttime: '', endtime: '', slot_name: '' }))
    }, [bookingData.date, bookingData.thid, bookingData.duration])

    useEffect(() => {
        if (bookingData.thid === 'gt_max') setbookingData((prevVal) => ({ ...prevVal, duration: 3 }))
    }, [bookingData.thid])

    useEffect(() => {
        getTheatreDetails()
        getDecorations()
        getCakes()
        const today = formatDate(new Date(), 'dateObject')
        setbookingData((prevVal) => ({ ...prevVal, date: today }))
    }, [])

    useEffect(() => {
        console.log(bookingData)
    }, [bookingData])

    const handleSubmit = () => {
        const headers = {
            'ngrok-skip-browser-warning': 'true'
        }
        axios.post(`https://5d84-122-176-172-74.ngrok-free.app/gtadmin/bookedslots?new=true`, bookingData, { headers: headers })
            .then(res => {
                setMessage('Order created successfully')
            })
            .catch(error => console.log(error))
    }


    if (message) {
        return (
            <div className="order-created">
                <p>{message}</p>
                <button onClick={() => setMessage('')}>Close</button>
            </div>
        )
    } else return (
        <table className='create-order'>
            <tr>
                <th>Booking Date</th>
                <td>
                    {/* <input type="date" onChange={(e) => setbookingData({ ...bookingData, date: formatDate(e.target.value, 'yyyy-mm-dd') })} /> */}
                    <ReactDatePicker
                        selected={bookingData.date}
                        onChange={(date) => setbookingData({ ...bookingData, date: formatDate(date, 'dateObject') })}
                        dateFormat="dd-MM-yyyy"
                        minDate={formatDate(new Date(), 'dateObject')}
                        showIcon
                        icon={
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.25 3.125H3.75C2.71447 3.125 1.875 3.96447 1.875 5V16.25C1.875 17.2855 2.71447 18.125 3.75 18.125H16.25C17.2855 18.125 18.125 17.2855 18.125 16.25V5C18.125 3.96447 17.2855 3.125 16.25 3.125Z" stroke="black" stroke-linejoin="round" />
                                <path d="M5 1.875V3.125M15 1.875V3.125M18.125 6.25H1.875" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        }
                    />
                </td>
            </tr>
            <tr>
                <th>Select Theatre</th>
                <td>
                    <select onChange={(e) => setbookingData({ ...bookingData, thid: e.target.value })}>
                        {
                            theatreDetails.map((theatre, index) => (
                                <option key={index} value={theatre.thid}>{theatre.name}</option>
                            ))
                        }
                    </select>
                </td>
            </tr>
            <tr>
                <th>Select Duration</th>
                <td>
                    <select value={bookingData.duration} onChange={(e) => setbookingData({ ...bookingData, duration: parseInt(e.target.value, 10) })}>
                        {bookingData.thid === 'gt_mini' && <option value={1}>1 hour</option>}
                        {bookingData.thid === 'gt_mini' && <option value={2}>2 hours</option>}
                        <option value={3}>3 hours</option>
                    </select>
                </td>
            </tr>
            <tr>
                <th>Select Slot</th>
                <td>
                    <div className="slots">

                        {bookingData.date ? slots.map((slot, index) => (
                            <div
                                key={index}
                                className={`slot ${bookingData.starttime === slot.starttime && bookingData.endtime === slot.endtime ? 'active' : ''} ${slot.isavailable ? '' : 'disabled'}`}
                                onClick={() => setbookingData({ ...bookingData, starttime: slot.starttime, endtime: slot.endtime, slot_name: slot.slot_name })}>
                                <p>{slot.starttime} - {slot.endtime}</p>
                            </div>
                        )) : <p>Select Date to view slots</p>}
                    </div>
                </td>
            </tr>
            <tr>
                <th>Number of Guests</th>
                {theatreDetails && <td><input type="number" value={bookingData.guests} min={2} onChange={(e) => setbookingData({ ...bookingData, guests: parseInt(e.target.value, 10) })} /></td>}
            </tr>
            <tr>
                <th>Customer Name</th>
                <td><input type="text" onChange={(e) => setbookingData({ ...bookingData, customer: { ...bookingData.customer, name: e.target.value } })} /></td>
            </tr>
            <tr>
                <th>Customer Phone</th>
                <td><input type="text" onChange={(e) => setbookingData({ ...bookingData, customer: { ...bookingData.customer, phone: e.target.value } })} /></td>
            </tr>
            <tr>
                <th>Customer Email (Optional) </th>
                <td><input type="text" onChange={(e) => setbookingData({ ...bookingData, customer: { ...bookingData.customer, email: e.target.value } })} /></td>
            </tr>
            <tr>
                <th>Select Decoration</th>
                <td>
                    <select onChange={(e) => setbookingData({ ...bookingData, decor_id: e.target.value })}>
                        <option value="">None</option>
                        {
                            decorations.map((decoration, index) => (
                                <option key={index} value={decoration.dec_id}>{decoration.name}</option>
                            ))
                        }

                    </select>
                </td>
            </tr>
            <tr>
                <th>Select Cakes</th>
                <td>
                    <select onChange={(e) => setbookingData({ ...bookingData, cake_id: e.target.value })}>
                        <option value="">None</option>
                        {
                            cakes.map((cake, index) => (
                                <option key={index} value={cake.dec_id}>{cake.name}</option>
                            ))
                        }
                    </select>
                </td>
            </tr>
            <tr>
                <th></th>
                <td>
                    <button onClick={handleSubmit}>Create Order</button>
                </td>
            </tr>
        </table>
    )
}

export default CreateOrder