import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
function Decorations() {
    const [decorations, setDecorations] = useState([])
    const API_URL = 'https://api.gametheory.cafe'
    useEffect(() => {
        const getDecorations = () => {
            axios.get(`${API_URL}/gtadmin/decorations`)
                .then(res => {
                    setDecorations(res.data)
                })
                .catch(err => console.log(err))
        }
        getDecorations()
    }, [])
    return (
        <table>
            <thead>
                <tr>
                    <th>Decoration id</th>
                    <th>Name</th>
                    <th>Price</th>
                </tr>
            </thead>
            <tbody>
                {
                    decorations.map((decoration, index) => (
                        <tr key={index}>
                            <td><Link to={`/decorations/${decoration.dec_id}`}>{decoration.dec_id}</Link></td>
                            <td>{decoration.name}</td>
                            <td>{decoration.price}</td>
                        </tr>
                    ))
                }
            </tbody>

        </table>
    )
}

export default Decorations