import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import '../styles/order.css'

const Decoration = () => {
    const { decid } = useParams()
    const [decoration, setDecoration] = useState({})
    const [isEditable, setIsEditable] = useState(false)
    const [saveChanges, setSaveChanges] = useState(false)
    const API_URL = 'https://api.gametheory.cafe'
    const navigate = useNavigate()

    useEffect(() => {

        axios.get(`${API_URL}/gtadmin/decorations?dcid=${decid}`)
            .then(res => {
                setDecoration(res.data[0])
            })
            .catch(err => console.log(err))
    }, [])
    const handleSaveChanges = () => {
        axios.post(`${API_URL}/gtadmin/decorations?dcid=${decid}`, decoration)
            .then(res => {
                setSaveChanges(!saveChanges)
                setDecoration(res.data[0])
            })
            .catch(err => console.log(err))
    }

    const handleEditToggle = () => {
        setIsEditable(!isEditable)
    }
    const handleInputChange = (e, key) => {
        e.preventDefault()
        if (!saveChanges) {
            setSaveChanges(true)
        }
        let value = e.target.value
        let tempValue = { ...decoration }
        tempValue[key] = value
        setDecoration(tempValue)
    }


    if (!decoration) {
        return (
            <div>
                Loading...
            </div>
        )
    } else return (
        <div className='order'>
            <div className="buttons">
                <button onClick={() => navigate(-1)} className='go-back'>
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.33032 9.99722C2.33032 14.4843 5.96821 18.1222 10.4553 18.1222C14.9424 18.1222 18.5803 14.4843 18.5803 9.99722C18.5803 5.51011 14.9424 1.87222 10.4553 1.87222C5.96821 1.87222 2.33032 5.51011 2.33032 9.99722ZM11.0803 6.86871L8.59673 9.37222H14.4397V10.6222H8.59673L11.0803 13.1257L10.1936 14.0062L6.21548 9.99722L10.1936 5.98824L11.0803 6.86871Z" fill="black" />
                    </svg>
                    Go Back
                </button>
                <div className=''>
                    <button onClick={handleEditToggle} className={isEditable ? 'edit-toggle edit' : 'edit-toggle'}>
                        <span className="circle">Edit</span>
                    </button>
                </div>
            </div>
            <table>
                <tr>
                    <th>Decoration ID</th>
                    <td>
                        <input type="text" onChange={(e) => handleInputChange(e, "dec_id")} value={decoration.dec_id} readOnly />
                    </td>
                </tr>

                <tr>
                    <th>Name</th>
                    <td>
                        <input type="text" onChange={(e) => handleInputChange(e, "name")} value={decoration.name} readOnly={!isEditable} />
                    </td>
                </tr>
                <tr>
                    <th>Price</th>
                    <td>
                        <input type="text" onChange={(e) => handleInputChange(e, "price")} value={decoration.price} readOnly={!isEditable} />
                    </td>
                </tr>

            </table>
            {saveChanges && <button onClick={handleSaveChanges}>Save Changes</button>}

        </div>
    )
}

export default Decoration