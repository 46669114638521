import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import '../styles/order.css'
import DatePicker from 'react-datepicker'

const Order = () => {
    const { orderid } = useParams()
    const [order, setOrder] = useState({})
    const [isEditable, setIsEditable] = useState(false)
    const [saveChanges, setSaveChanges] = useState(false)
    const API_URL = 'https://api.gametheory.cafe'
    const navigate = useNavigate()

    useEffect(() => {
        axios.get(`${API_URL}/gtadmin/bookedslots?orderid=${orderid}`)
            .then(res => {
                setOrder(res.data[0])
            })
            .catch(err => console.log(err))
    }, [])
    const handleSaveChanges = () => {
        axios.post(`${API_URL}/gtadmin/bookedslots?orderid=${orderid}`, order)
            .then(res => {
                setSaveChanges(!saveChanges)
                setOrder(res.data[0])
            })
            .catch(err => console.log(err))
    }

    const handleEditToggle = () => {
        setIsEditable(!isEditable)
    }
    const handleInputChange = (e, key, subkey) => {
        e.preventDefault()
        let tempValue = { ...order }
        let value = e.target.value
        const nameRegex = /^[a-zA-Z\s]*$/
        const numberRegex = /^[0-9]*$/

        if (key === '') {
            if (subkey === 'totalprice' || subkey === 'decorationPrice' || subkey === 'cakePrice') {
                if (!numberRegex.test(value)) {
                    return alert(`Please enter a valid number for '${subkey}'`)
                } else {
                    tempValue[subkey] = value
                }
            }
        }
        if (key !== '') {
            if (subkey === 'name') {
                if (!nameRegex.test(value)) {
                    return alert('Please enter a valid name')
                } else {
                    tempValue[key][subkey] = value
                }
            }
            if (subkey === 'phone') {
                if (!numberRegex.test(value)) {
                    return alert('Please enter a valid phone number')
                } else {
                    tempValue[key][subkey] = value
                }
            }

        }

        setOrder(tempValue)
    }

    const cancelOrder = (id) => {
        axios.post(`${API_URL}/gtadmin/bookedslots?cancel=true&orderid=${id}`)
            .then(res => {
                alert('Order Cancelled')
            })
            .catch(err => console.log(err))
    }
    const formatDate = (dateValue, formatTo) => {
        if (formatTo === 'dd-mm-yyyy') {
            const [day, month, year] = dateValue.split('-');
            return `${day}-${month}-${year}`;
        }
        if (formatTo === 'yyyy-mm-dd') {
            const [day, month, year] = dateValue.split('-');
            return `${year}-${month}-${day}`;
        }
        if (formatTo === 'dateObject') {
            const day = dateValue.getDate();
            const month = dateValue.getMonth() + 1;
            const year = dateValue.getFullYear();
            return `${day}-${month}-${year}`;
        }
    };

    if (!order.created_date) {
        return (
            <div>
                Loading...
            </div>
        )
    } else return (
        <div className='order'>
            <div className="buttons">
                <button onClick={() => navigate(-1)} className='go-back'>
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.33032 9.99722C2.33032 14.4843 5.96821 18.1222 10.4553 18.1222C14.9424 18.1222 18.5803 14.4843 18.5803 9.99722C18.5803 5.51011 14.9424 1.87222 10.4553 1.87222C5.96821 1.87222 2.33032 5.51011 2.33032 9.99722ZM11.0803 6.86871L8.59673 9.37222H14.4397V10.6222H8.59673L11.0803 13.1257L10.1936 14.0062L6.21548 9.99722L10.1936 5.98824L11.0803 6.86871Z" fill="black" />
                    </svg>
                    Go Back
                </button>
                <div className=''>
                    <button onClick={handleEditToggle} className={isEditable ? 'edit-toggle edit' : 'edit-toggle'}>
                        <span className="circle">Edit</span>
                    </button>
                </div>
            </div>
            <table>
                <tr>
                    <th>Order ID</th>
                    <td>
                        <input type="text" value={order.orderid} readOnly />

                    </td>
                </tr>
                <tr>
                    <th>Order Date</th>
                    <td>
                        <input
                            type="date"
                            value={formatDate(order.created_date.split(', ')[0], "yyyy-mm-dd")}
                            readOnly
                        />
                    </td>
                </tr>
                <tr>
                    <th>Theatre ID</th>
                    <td>

                        <input type="text" onChange={(e) => handleInputChange(e, '', "thid")} value={order.thid} readOnly={!isEditable} />

                    </td>
                </tr>
                <tr>
                    <th>Name</th>
                    <td>

                        <input type="text" onChange={(e) => handleInputChange(e, 'customer', "name")} value={order.customer.name} readOnly={!isEditable} />

                    </td>
                </tr>

                <tr>
                    <th>Phone</th>
                    <td>

                        <input type="text" onChange={(e) => handleInputChange(e, 'customer', "phone")} value={order.customer.phone} readOnly={!isEditable} />

                    </td>
                </tr>
                <tr>
                    <th>No. of persons</th>
                    <td>

                        <input type="text" onChange={(e) => handleInputChange(e, '', "guests")} value={order.guests} readOnly={!isEditable} />

                    </td>
                </tr>
                <tr>
                    <th>Booking Date</th>
                    <td>
                        {/* <input
                            type="date"
                            value={formatDate(order.date.split(', ')[0], "yyyy-mm-dd")}
                            onChange={(e) => handleInputChange(e, "date")}
                            readOnly={!isEditable}
                        /> */}
                        {/* <p>{formatDate(order.date.split(', ')[0], "yyyy-mm-dd")}</p> */}
                        <DatePicker
                            selected={formatDate(order.date.split(', ')[0], "yyyy-mm-dd")}
                            onChange={(date) => {
                                setOrder({ ...order, date: formatDate(date, 'dateObject') })
                                if (!saveChanges) {
                                    setSaveChanges(true)
                                }
                            }}
                            dateFormat={"dd/MM/yyyy"}
                            dateFormatCalendar='dd/MM/yyyy'
                            minDate={formatDate(formatDate(new Date(), 'dateObject'), 'yyyy-mm-dd')}
                            showIcon
                            readOnly={!isEditable}
                            icon={
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.25 3.125H3.75C2.71447 3.125 1.875 3.96447 1.875 5V16.25C1.875 17.2855 2.71447 18.125 3.75 18.125H16.25C17.2855 18.125 18.125 17.2855 18.125 16.25V5C18.125 3.96447 17.2855 3.125 16.25 3.125Z" stroke="black" stroke-linejoin="round" />
                                    <path d="M5 1.875V3.125M15 1.875V3.125M18.125 6.25H1.875" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            }
                        />
                    </td>
                </tr>
                <tr>
                    <th>Cake</th>
                    <td>

                        <input type="text" onChange={(e) => handleInputChange(e, '', "cake_name")} value={order.cake_name} readOnly={!isEditable} />

                    </td>
                </tr>
                <tr>
                    <th>Cake Price</th>
                    <td>

                        <input type="text" value={order.cakePrice} onChange={(e) => handleInputChange(e, '', "cakePrice")} readOnly={!isEditable} />

                    </td>
                </tr>
                <tr>
                    <th>Decoration</th>
                    <td>

                        <input type="text" value={order.decoration_name} onChange={(e) => handleInputChange(e, '', "decoration_name")} readOnly={!isEditable} />

                    </td>
                </tr>
                <tr>
                    <th>Decoration Price</th>
                    <td>

                        <input type="text" value={order.decorationPrice} onChange={(e) => handleInputChange(e, '', "decorationPrice")} readOnly={!isEditable} />

                    </td>
                </tr>
                <tr>
                    <th>Pay ref</th>
                    <td>

                        <input type="text" value={order.payref} onChange={(e) => handleInputChange(e, '', "payref")} readOnly={!isEditable} />

                    </td>
                </tr>
                <tr>
                    <th>Total price</th>
                    <td>

                        <input type="text" value={order.totalprice} onChange={(e) => handleInputChange(e, '', "totalprice")} readOnly={!isEditable} />

                    </td>
                </tr>
                <tr>
                    <th>Slot Start Time</th>
                    <td>
                        <input type="text" value={order.starttime} onChange={(e) => handleInputChange(e, '', "starttime")} readOnly={!isEditable} />
                    </td>
                </tr>
                <tr>
                    <th>Slot End Time</th>
                    <td>
                        <input type="text" value={order.endtime} onChange={(e) => handleInputChange(e, '', "endtime")} readOnly={!isEditable} />
                    </td>
                </tr>
                <tr>
                    <th></th>
                    <td>
                        {order.active ? <button onClick={() => cancelOrder(order.orderid)} className='cancel-button'>Cancel Order</button> : 'Order Cancelled'}
                    </td>
                </tr>
            </table>
            {saveChanges && <button className='save-button' onClick={handleSaveChanges}>Save Changes</button>}

        </div>
    )
}

export default Order