import { useState } from 'react'
import '../styles/sidebar.css'
import GametheoryLogo from '../assets/GametheoryLogo'
import NavLinks from './NavLink'
import { Link } from 'react-router-dom'
import { PaymentsIcon, TheatreIcon, OrderIcon, CakeIcon, DecoratorIcon } from './Icons'

function SideBar() {
    const [sideBarData, setSideBarData] = useState([
        {
            name: 'Orders',
            path: ['/orders'],
            isExpanable: true,
            isActive: true,
            Icon: <OrderIcon />,
            children: [
                {
                    name: 'All Orders',
                    path: '/orders',
                },
                {
                    name: 'Create Order',
                    path: '/create-order',
                }
            ]
        },
        {
            name: 'Payments',
            path: '/payments',
            isExpanable: false,
            Icon: <PaymentsIcon />,
            isActive: false,
        },
        {
            name: 'Theatres',
            path: '/theatres',
            isExpanable: false,
            Icon: <TheatreIcon />,
            isActive: false,
        },
        {
            name: 'Cakes',
            path: '/cakes',
            isExpanable: true,
            Icon: <CakeIcon />,
            children: [
                {
                    name: 'All Cakes',
                    path: '/cakes',
                },
                {
                    name: 'Create Cake',
                    path: '/create-cake',
                }
            ],
            isActive: false,
        },
        {
            name: 'Decorations',
            path: '/decorations',
            isExpanable: true,
            Icon: <DecoratorIcon />,
            children: [
                {
                    name: 'All Decorations',
                    path: '/decorations',
                },
                {
                    name: 'Create Decoration',
                    path: '/create-decoration',
                }
            ],
            isActive: false,
        },
    ])
    // const toggleNavItem = (index) => {
    //     alert(sideBarData[index].name)
    //     let tempData = sideBarData
    //     tempData[index].isActive = !tempData[index].isActive
    //     setSideBarData(tempData)
    // }
    return (
        <aside className="side_bar">
            <div className="logo">
                <GametheoryLogo />
                <div>
                    <p>GameTheory</p>
                    <h3> Admin</h3>
                </div>
            </div>
            <ul className="side-nav">
                {sideBarData.map((item, index) => {
                    if (item.isExpanable) {
                        return (
                            <li key={index} className={` ${item.isActive ? 'active' : ''}`}>
                                <div onClick={() => setSideBarData(prev => prev.map((item, i) => i === index ? { ...item, isActive: !item.isActive } : item))} className={`side-nav-item`} >
                                    {item.Icon}
                                    {item.name}
                                    <svg
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={item.isActive ? { transform: 'rotate(180deg)', transition: 'all 0.5s' } : { transform: 'rotate(0deg)', transition: 'all 0.5s' }}
                                    >
                                        <path
                                            d="M2.87105 5.56817L6.96616 10.3471C7.03217 10.4241 7.11405 10.4859 7.20619 10.5282C7.29833 10.5706 7.39854 10.5925 7.49995 10.5925C7.60136 10.5925 7.70158 10.5706 7.79371 10.5282C7.88585 10.4859 7.96773 10.4241 8.03374 10.3471L12.1289 5.56817C12.5197 5.11201 12.1957 4.40742 11.5951 4.40742H3.40366C2.80308 4.40742 2.47905 5.11201 2.87105 5.56817Z"
                                            fill="#222"
                                        />
                                    </svg>
                                </div>
                                <div className="side-nav-sub-item">

                                    {item.isActive && item.children.map((child, index) => {
                                        return (
                                            <li key={index}>
                                                <Link to={child.path}>
                                                    {child.name}
                                                </Link>
                                            </li>
                                        )
                                    })}
                                </div>
                            </li>
                        )
                    } else return (
                        <li key={index}>
                            <Link to={item.path} className="side-nav-item">
                                {item.Icon}
                                {item.name}
                            </Link>
                        </li>
                    )
                })}
            </ul>

        </aside>
    )
}

export default SideBar