import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import '../styles/order.css'

const Theatre = () => {
    const { thid } = useParams()
    const [theatre, setTheatre] = useState({})
    const [isEditable, setIsEditable] = useState(false)
    const [saveChanges, setSaveChanges] = useState(false)
    const API_URL = 'https://api.gametheory.cafe'
    const navigate = useNavigate()

    useEffect(() => {

        axios.get(`${API_URL}/gtadmin/theatredetails?thid=${thid}`)
            .then(res => {
                setTheatre(res.data[0])
            })
            .catch(err => console.log(err))
    }, [])
    const handleSaveChanges = () => {
        axios.post(`${API_URL}/gtadmin/theatredetails?thid=${thid}`, theatre)
            .then(res => {
                setSaveChanges(!saveChanges)
                setTheatre(res.data[0])
            })
            .catch(err => console.log(err))
    }

    const handleEditToggle = () => {
        setIsEditable(!isEditable)
    }
    const handleInputChange = (e, key) => {
        e.preventDefault()
        if (!saveChanges) {
            setSaveChanges(true)
        }
        let value = e.target.value
        let tempValue = { ...theatre }
        tempValue[key] = value
        setTheatre(tempValue)
    }
    const handleSpecsChange = (e, index) => {
        e.preventDefault()
        if (!saveChanges) {
            setSaveChanges(true)
        }
        let value = e.target.value
        let tempValue = { ...theatre }
        tempValue.specs[index] = value
        setTheatre(tempValue)
    }
    const handlePricingChange = (e, index, key) => {
        e.preventDefault()
        if (!saveChanges) {
            setSaveChanges(true)
        }
        let value = e.target.value
        let tempValue = { ...theatre }
        tempValue.pricing[index][key] = value
        setTheatre(tempValue)
    }


    if (!theatre) {
        return (
            <div>
                Loading...
            </div>
        )
    } else return (
        <div className='order'>
            <div className="buttons">
                <button onClick={() => navigate(-1)} className='go-back'>
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.33032 9.99722C2.33032 14.4843 5.96821 18.1222 10.4553 18.1222C14.9424 18.1222 18.5803 14.4843 18.5803 9.99722C18.5803 5.51011 14.9424 1.87222 10.4553 1.87222C5.96821 1.87222 2.33032 5.51011 2.33032 9.99722ZM11.0803 6.86871L8.59673 9.37222H14.4397V10.6222H8.59673L11.0803 13.1257L10.1936 14.0062L6.21548 9.99722L10.1936 5.98824L11.0803 6.86871Z" fill="black" />
                    </svg>
                    Go Back
                </button>
                <div className=''>
                    <button onClick={handleEditToggle} className={isEditable ? 'edit-toggle edit' : 'edit-toggle'}>
                        <span className="circle">Edit</span>
                    </button>
                </div>
            </div>
            <table>
                <tr>
                    <th>Theatre ID</th>
                    <td>
                        <input type="text" onChange={(e) => handleInputChange(e, "thid")} value={theatre.thid} readOnly />
                    </td>
                </tr>

                <tr>
                    <th>Name</th>
                    <td>
                        <input type="text" onChange={(e) => handleInputChange(e, "name")} value={theatre.name} readOnly={!isEditable} />
                    </td>
                </tr>
                <tr>
                    <th>Max Capacity</th>
                    <td>
                        <input type="text" onChange={(e) => handleInputChange(e, "max_capacity")} value={theatre.max_capacity} readOnly={!isEditable} />
                    </td>
                </tr>
                <tr>
                    <th>Specs</th>
                    <td>
                        <table>
                            <tbody>
                                {
                                    theatre.specs && theatre.specs.map((spec, index) => (
                                        <tr key={index}>
                                            <td><input type="text" onChange={(e) => handleSpecsChange(e, index)} value={spec} readOnly={!isEditable} /></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <th>Pricing</th>
                    <td>
                        <table>
                            <thead>
                                <tr>
                                    <th>Duration (Hours)</th>
                                    <th>Base Price </th>
                                    <th>Base Persons</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    theatre.pricing && theatre.pricing.map((price, index) => (
                                        <tr key={index}>
                                            <td><input type="text" onChange={(e) => handlePricingChange(e, index, "duration")} value={price.duration} readOnly={!isEditable} placeholder="Duration" /></td>
                                            <td><input type="text" onChange={(e) => handlePricingChange(e, index, "base_price")} value={price.base_price} readOnly={!isEditable} placeholder="Base Price" /></td>
                                            <td><input type="text" onChange={(e) => handlePricingChange(e, index, "base_persons")} value={price.base_persons} readOnly={!isEditable} placeholder="Base Persons" /></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <th>Images</th>
                    <td>
                        <table>
                            <tbody>
                                {
                                    theatre.images && theatre.images.map((image, index) => (
                                        <tr key={index}>
                                            <td>
                                                <input type="url" onChange={(e) => handleInputChange(e, "images")} value={image} readOnly={!isEditable} placeholder="Image URL" />
                                                <img style={{ marginTop: '10px', width: 'auto', height: '300px', objectFit: 'contain' }} src={image} alt="image" />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <th>Videos</th>
                    <td>
                        <table>
                            <tbody>
                                {
                                    theatre.videos && theatre.videos.map((video, index) => (
                                        <tr key={index}>
                                            <td>
                                                <input type="url" onChange={(e) => handleInputChange(e, "videos")} value={video} readOnly={!isEditable} placeholder="Video URL" />
                                                {/* <video style={{ marginTop: '10px', width: 'auto', height: '300px', objectFit: 'contain' }} src={video} controls /> */}
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </td>
                </tr>
            </table>
            {saveChanges && <button style={{ position: 'fixed', bottom: '30px', right: '30px', fontSize: '20px', padding: '10px 20px', borderRadius: '10px' }} onClick={handleSaveChanges}>Save Changes</button>}

        </div>
    )
}

export default Theatre