import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import '../styles/order.css'

const Cake = () => {
    const { cakeid } = useParams()
    const [cake, setCake] = useState({})
    const [isEditable, setIsEditable] = useState(false)
    const [saveChanges, setSaveChanges] = useState(false)
    const API_URL = 'https://api.gametheory.cafe'
    const navigate = useNavigate()

    useEffect(() => {

        axios.get(`${API_URL}/gtadmin/cakes?cakeid=${cakeid}`)
            .then(res => {
                setCake(res.data[0])
            })
            .catch(err => console.log(err))
    }, [])
    const handleSaveChanges = () => {
        axios.post(`${API_URL}/gtadmin/cakes?cakeid=${cakeid}`, cake)
            .then(res => {
                setSaveChanges(!saveChanges)
                setCake(res.data[0])
            })
            .catch(err => console.log(err))
    }

    const handleEditToggle = () => {
        setIsEditable(!isEditable)
    }
    const handleInputChange = (e, key) => {
        e.preventDefault()
        if (!saveChanges) {
            setSaveChanges(true)
        }
        let value = e.target.value
        let tempValue = { ...cake }
        tempValue[key] = value
        setCake(tempValue)
    }


    if (!cake.cake_id) {
        return (
            <div>
                Loading...
            </div>
        )
    } else return (
        <div className='order'>
            <div className="buttons">
                <button onClick={() => navigate(-1)} className='go-back'>
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.33032 9.99722C2.33032 14.4843 5.96821 18.1222 10.4553 18.1222C14.9424 18.1222 18.5803 14.4843 18.5803 9.99722C18.5803 5.51011 14.9424 1.87222 10.4553 1.87222C5.96821 1.87222 2.33032 5.51011 2.33032 9.99722ZM11.0803 6.86871L8.59673 9.37222H14.4397V10.6222H8.59673L11.0803 13.1257L10.1936 14.0062L6.21548 9.99722L10.1936 5.98824L11.0803 6.86871Z" fill="black" />
                    </svg>
                    Go Back
                </button>
                <div className=''>
                    <button onClick={handleEditToggle} className={isEditable ? 'edit-toggle edit' : 'edit-toggle'}>
                        <span className="circle">Edit</span>
                    </button>
                </div>
            </div>
            <table>
                <tr>
                    <th>Cake ID</th>
                    <td>
                        <input type="text" onChange={(e) => handleInputChange(e, "cake_id")} value={cake.cake_id} readOnly />
                    </td>
                </tr>

                <tr>
                    <th>Name</th>
                    <td>
                        <input type="text" onChange={(e) => handleInputChange(e, "name")} value={cake.name} readOnly={!isEditable} />
                    </td>
                </tr>
                <tr>
                    <th>Price</th>
                    <td>
                        <input type="text" onChange={(e) => handleInputChange(e, "price")} value={cake.price} readOnly={!isEditable} />
                    </td>
                </tr>
                <tr>
                    <th>Description</th>
                    <td>
                        <input type="text" onChange={(e) => handleInputChange(e, "description")} value={cake.description} readOnly={!isEditable} />
                    </td>
                </tr>
                <tr>
                    <th>Image</th>
                    <td>
                        <input type='url' onChange={(e) => handleInputChange(e, "img")} value={cake.img} readOnly={!isEditable} />
                        <div style={{ marginTop: '10px' }}>Preview:</div>
                        <img src={cake.img} style={{ marginTop: '10px', width: 'auto', height: '300px', objectFit: 'contain' }} className="cake-image" alt="cake" />
                    </td>
                </tr>

            </table>
            {saveChanges && <button onClick={handleSaveChanges}>Save Changes</button>}

        </div>
    )
}

export default Cake