import axios from 'axios'
import { useEffect, useState } from 'react'
import '../styles/payments.css'
import { Link } from 'react-router-dom'


function Payments() {
    const [payments, setPayments] = useState([])
    const [activeTab, setActiveTab] = useState('all')
    const API_URL = 'https://api.gametheory.cafe'

    useEffect(() => {
        axios.get(`${API_URL}/gtadmin/payments`)
            .then(res => {
                setPayments(res.data)
            })
            .catch(err => console.log(err))
    }, [])
    const All = ({ payments }) => {
        return (
            <table>
                <thead>
                    <tr>
                        <th>Order ID</th>
                        <th>Name</th>
                        <th>Order Total</th>
                        <th>Amount Due</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        payments.map((payment, index) => (
                            <tr key={index}>
                                <td><Link to={`/payment/${payment.orderid}`}>{payment.orderid}</Link></td>
                                <td>{payment.customer.name}</td>
                                <td>{payment.totalprice}</td>
                                <td>{payment.amount_due}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        )
    }

    const Pending = ({ payments }) => {
        return (
            <table>
                <thead>
                    <tr>
                        <th>Order ID</th>
                        <th>Name</th>
                        <th>Order Total</th>
                        <th>Amount Due</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        payments.filter(payment => payment.amount_due > 0).map((payment, index) => (
                            <tr key={index}>
                                <td><Link to={`/payment/${payment.orderid}`}>{payment.orderid}</Link></td>
                                <td>{payment.customer.name}</td>
                                <td>{payment.totalprice}</td>
                                <td>{payment.amount_due}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        )
    }

    const Paid = ({ payments }) => {
        return (
            <table>
                <thead>
                    <tr>
                        <th>Order ID</th>
                        <th>Name</th>
                        <th>Order Total</th>
                        <th>Amount Due</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        payments.filter(payment => payment.amount_due === 0).map((payment, index) => (
                            <tr key={index}>
                                <td><Link to={`/payment/${payment.orderid}`}>{payment.orderid}</Link></td>
                                <td>{payment.customer.name}</td>
                                <td>{payment.totalprice}</td>
                                <td>{payment.amount_due}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        )
    }

    return (
        <div className='payments'>
            <div className="payment-tabs-heading">
                <div className={activeTab === 'all' ? 'active' : ''} onClick={() => setActiveTab('all')}>All</div>
                <div className={activeTab === 'pending' ? 'active' : ''} onClick={() => setActiveTab('pending')}>Pending</div>
                <div className={activeTab === 'paid' ? 'active' : ''} onClick={() => setActiveTab('paid')}>Paid</div>
            </div>
            <div className="payments-tabs">
                {
                    activeTab === 'all' ? <All payments={payments} /> : activeTab === 'pending' ? <Pending payments={payments} /> : <Paid payments={payments} />
                }

            </div>
        </div>
    )
}

export default Payments