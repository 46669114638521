import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import '../styles/order.css'

const Order = () => {
    const { orderid } = useParams()
    const [payment, setPayment] = useState({})
    const [isEditable, setIsEditable] = useState(false)
    const [saveChanges, setSaveChanges] = useState(false)
    const API_URL = 'https://api.gametheory.cafe'
    const navigate = useNavigate()

    useEffect(() => {

        axios.get(`${API_URL}/gtadmin/payments?orderid=${orderid}`)
            .then(res => {
                setPayment(res.data[0])
            })
            .catch(err => console.log(err))
    }, [])
    const handleSaveChanges = () => {
        axios.post(`${API_URL}/gtadmin/payments?orderid=${orderid}`, payment)
            .then(res => {
                setSaveChanges(!saveChanges)
                setPayment(res.data[0])
            })
            .catch(err => console.log(err))
    }

    const handleEditToggle = () => {
        setIsEditable(!isEditable)
    }
    const handleInputChange = (e, key, subkey) => {
        e.preventDefault()
        let value = e.target.value
        let tempValue = { ...payment }
        const nameRegex = /^[a-zA-Z\s]*$/
        const numberRegex = /^[0-9]*$/

        if (!saveChanges) {
            setSaveChanges(true)
        }
        if (key === '') {
            if (subkey === 'totalprice' || subkey === 'amount_due') {
                if (!numberRegex.test(value)) {
                    return alert(`Please enter a valid number for '${subkey}'`)
                } else {
                    tempValue[subkey] = value
                }
            }
        }
        if (key !== '') {
            if (subkey === 'name') {
                if (!nameRegex.test(value)) {
                    return alert('Please enter a valid name')
                } else {
                    tempValue[key][subkey] = value
                }
            }
            if (subkey === 'phone') {
                if (!numberRegex.test(value)) {
                    return alert('Please enter a valid phone number')
                } else {
                    tempValue[key][subkey] = value
                }
            }

        }
        setPayment(tempValue)
    }


    if (!payment.orderid) {
        return (
            <div>
                Loading...
            </div>
        )
    } else return (
        <div className='order'>
            <div className="buttons">
                <button onClick={() => navigate(-1)} className='go-back'>
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.33032 9.99722C2.33032 14.4843 5.96821 18.1222 10.4553 18.1222C14.9424 18.1222 18.5803 14.4843 18.5803 9.99722C18.5803 5.51011 14.9424 1.87222 10.4553 1.87222C5.96821 1.87222 2.33032 5.51011 2.33032 9.99722ZM11.0803 6.86871L8.59673 9.37222H14.4397V10.6222H8.59673L11.0803 13.1257L10.1936 14.0062L6.21548 9.99722L10.1936 5.98824L11.0803 6.86871Z" fill="black" />
                    </svg>
                    Go Back
                </button>
                <div className=''>
                    <button onClick={handleEditToggle} className={isEditable ? 'edit-toggle edit' : 'edit-toggle'}>
                        <span className="circle">Edit</span>
                    </button>
                </div>
            </div>
            <table>
                <tr>
                    <th>Order ID</th>
                    <td>
                        <input type="text" onChange={(e) => handleInputChange(e, "orderid")} value={payment.orderid} readOnly />
                    </td>
                </tr>
                <tr>
                    <th>Order Total</th>
                    <td>
                        <input
                            type="text"
                            value={payment.totalprice}
                            onChange={(e) => handleInputChange(e, '', "totalprice")}
                            readOnly={!isEditable}
                        />
                    </td>
                </tr>
                <tr>
                    <th>Amount Due</th>
                    <td>
                        <input type="text" onChange={(e) => handleInputChange(e, '', "amount_due")} value={payment.amount_due} readOnly={!isEditable} />
                    </td>
                </tr>
                <tr>
                    <th>Name</th>
                    <td>
                        <input type="text" onChange={(e) => handleInputChange(e, 'customer', "name")} value={payment.customer.name} readOnly={!isEditable} />
                    </td>
                </tr>
                <tr>
                    <th>Phone</th>
                    <td>
                        <input type="text" onChange={(e) => handleInputChange(e, 'customer', "phone")} value={payment.customer.phone} readOnly={!isEditable} />
                    </td>
                </tr>

            </table>
            {saveChanges && <button className='save-button' onClick={handleSaveChanges}>Save Changes</button>}

        </div>
    )
}

export default Order