import { useEffect, useState } from 'react'
import axios from 'axios'
import '../styles/create-order.css'
const CreateDecoration = () => {
    const [message, setMessage] = useState('')
    const [decData, setDecData] = useState({
        name: '',
        price: '',
        description: ''
    })
    const API_URL = 'https://api.gametheory.cafe'

    useEffect(() => {
        console.log(decData)
    }, [decData])

    const handleSubmit = () => {
        axios.post(`${API_URL}/gtadmin/decorations`, decData)
            .then(res => {
                setMessage('Decoration created successfully')
            })
            .catch(error => console.log(error))
    }


    if (message) {
        return (
            <div className="order-created">
                <p>{message}</p>
                <button onClick={() => setMessage('')}>Close</button>
            </div>
        )
    } else return (
        <table className='create-order'>
            <tr>
                <th>Decoration Name</th>
                <td>
                    <input type="texy" onChange={(e) => setDecData({ ...decData, name: e.target.value })} />
                </td>
            </tr>
            <tr>
                <th>Price</th>
                <td>
                    <input type="number" onChange={(e) => setDecData({ ...decData, price: parseInt(e.target.value, 10) })} />
                </td>
            </tr>
            <tr>
                <th>Description</th>
                <td>
                    <input type="texy" onChange={(e) => setDecData({ ...decData, description: e.target.value })} />
                </td>
            </tr>
            <tr>
                <th></th>
                <td>
                    {<button onClick={handleSubmit}>Create Decoration</button>}
                </td>
            </tr>
        </table>
    )
}

export default CreateDecoration