import { useEffect, useState } from 'react'
import axios from 'axios'
import '../styles/create-order.css'
const CreateCake = () => {
    const [message, setMessage] = useState('')
    const [cakeData, setCakeData] = useState({
        name: '',
        price: '',
        description: '',
        img: '',
    })
    const API_URL = 'https://api.gametheory.cafe'

    useEffect(() => {
        console.log(cakeData)
    }, [cakeData])

    const handleSubmit = () => {
        axios.post(`${API_URL}/gtadmin/cakes`, cakeData)
            .then(res => {
                setMessage('Cake created successfully')
            })
            .catch(error => console.log(error))
    }


    if (message) {
        return (
            <div className="order-created">
                <p>{message}</p>
                <button onClick={() => setMessage('')}>Close</button>
            </div>
        )
    } else return (
        <table className='create-order'>
            <tr>
                <th>Cake Name</th>
                <td>
                    <input type="texy" onChange={(e) => setCakeData({ ...cakeData, name: e.target.value })} />
                </td>
            </tr>
            <tr>
                <th>Price</th>
                <td>
                    <input type="number" onChange={(e) => setCakeData({ ...cakeData, price: parseInt(e.target.value, 10) })} />
                </td>
            </tr>
            <tr>
                <th>Description</th>
                <td>
                    <input type="text" onChange={(e) => setCakeData({ ...cakeData, description: e.target.value })} />
                </td>
            </tr>
            <tr>
                <th>Image</th>
                <td>
                    <input type="url" onChange={(e) => setCakeData({ ...cakeData, image: e.target.value })} />
                    {
                        cakeData.img && (
                            <>
                                <div style={{ marginTop: '10px' }}>Preview:</div>
                                <img src={cakeData.img} style={{ marginTop: '10px', width: 'auto', height: '300px', objectFit: 'contain' }} className="cake-image" alt="cake" />
                            </>
                        )
                    }
                </td>
            </tr>
            <tr>
                <th></th>
                <td>
                    {<button onClick={handleSubmit}>Create Cake</button>}
                </td>
            </tr>
        </table>
    )
}

export default CreateCake