import React, { useEffect, useState } from 'react'
import '../styles/filters.css'
import { Link } from 'react-router-dom'
import axios from 'axios'

function Orders() {

    const [orders, setOrders] = useState([])
    const API_URL = 'https://api.gametheory.cafe'

    useEffect(() => {
        axios.get(`${API_URL}/gtadmin/bookedslots`)
            .then(res => {
                setOrders(res.data)
                console.log(res.data)
            })
            .catch(err => console.log(err))
    }, [])

    const cancelOrder = (id) => {
        axios.get(`${API_URL}/gtadmin/bookedslots?orderid=${id}&cancel=cancel`)
            .then(res => {
                console.log(res.data)
            })
            .catch(err => console.log(err))
    }
    return (
        <div className='orders'>
            <div className="filters">
                <div>
                    <div>
                        Latest
                        <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M2.87105 5.56817L6.96616 10.3471C7.03217 10.4241 7.11405 10.4859 7.20619 10.5282C7.29833 10.5706 7.39854 10.5925 7.49995 10.5925C7.60136 10.5925 7.70158 10.5706 7.79371 10.5282C7.88585 10.4859 7.96773 10.4241 8.03374 10.3471L12.1289 5.56817C12.5197 5.11201 12.1957 4.40742 11.5951 4.40742H3.40366C2.80308 4.40742 2.47905 5.11201 2.87105 5.56817Z"
                                fill="#222"
                            />
                        </svg>
                    </div>
                    <div>
                        Theatre
                        <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M2.87105 5.56817L6.96616 10.3471C7.03217 10.4241 7.11405 10.4859 7.20619 10.5282C7.29833 10.5706 7.39854 10.5925 7.49995 10.5925C7.60136 10.5925 7.70158 10.5706 7.79371 10.5282C7.88585 10.4859 7.96773 10.4241 8.03374 10.3471L12.1289 5.56817C12.5197 5.11201 12.1957 4.40742 11.5951 4.40742H3.40366C2.80308 4.40742 2.47905 5.11201 2.87105 5.56817Z"
                                fill="#222"
                            />
                        </svg>
                    </div>
                    <div>
                        All
                        <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M2.87105 5.56817L6.96616 10.3471C7.03217 10.4241 7.11405 10.4859 7.20619 10.5282C7.29833 10.5706 7.39854 10.5925 7.49995 10.5925C7.60136 10.5925 7.70158 10.5706 7.79371 10.5282C7.88585 10.4859 7.96773 10.4241 8.03374 10.3471L12.1289 5.56817C12.5197 5.11201 12.1957 4.40742 11.5951 4.40742H3.40366C2.80308 4.40742 2.47905 5.11201 2.87105 5.56817Z"
                                fill="#222"
                            />
                        </svg>
                    </div>
                </div>
                <div>
                    <span className="search">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M14.71 14.5H15.2926L19.7832 18.9996L18.9996 19.7832L14.5 15.2926V14.71V14.5082L14.3599 14.3629L14.2786 14.2786L14.3629 14.3599L14.5082 14.5H14.71ZM13.7519 13.7519L13.4041 14.0508C12.3512 14.956 10.9857 15.5 9.5 15.5C6.18614 15.5 3.5 12.8139 3.5 9.5C3.5 6.18614 6.18614 3.5 9.5 3.5C12.8139 3.5 15.5 6.18614 15.5 9.5C15.5 10.9857 14.956 12.3512 14.0508 13.4041L13.7519 13.7519ZM4.5 9.5C4.5 12.2661 6.73386 14.5 9.5 14.5C12.2661 14.5 14.5 12.2661 14.5 9.5C14.5 6.73386 12.2661 4.5 9.5 4.5C6.73386 4.5 4.5 6.73386 4.5 9.5Z"
                                stroke="#898989"
                            />
                        </svg>
                        <input type="text" placeholder="order id" />
                    </span>
                </div>
            </div>
            <table>
                <tr>
                    <th>Order ID</th>
                    <th>Order Date</th>
                    <th>Th Name</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Guests</th>
                    <th>Booking Date</th>
                    <th>Slot Time</th>
                    <th>Order Status</th>
                    <th>Total Price</th>
                    <th></th>
                </tr>
                {orders.map((order) => {
                    return (
                        <tr>
                            <td>
                                <Link to={`/order/${order.orderid}`}>{order.orderid}</Link>
                            </td>
                            <td>{order.created_date.split(',')[0]}</td>
                            <td>{order.thid === 'gt_mini' ? 'GT Mini' : 'GT Max'}</td>
                            <td>{order.customer.name}</td>
                            <td>{order.customer.phone}</td>
                            <td>{order.guests}</td>
                            <td>{order.date.split(',')[0]}</td>
                            <td>{order.starttime} - {order.endtime}</td>
                            <td>{order.order_status}</td>
                            <td>{order.totalprice}</td>
                            <td><button onClick={() => cancelOrder(order.orderid)}>Cancel</button></td>
                        </tr>
                    )
                })}
            </table>
        </div>
    )
}

export default Orders