import React from 'react'
import Navbar from './Navbar'
import SideBar from './SideBar'

function Layout({ children }) {
    return (
        <div className="content_wrapper">
            <SideBar />
            <main>
                <Navbar />
                <section className="main_page">
                    {children}
                </section>
            </main>
        </div>
    )
}

export default Layout