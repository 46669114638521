import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../pages/Home'
import Payments from '../pages/Payments'
import Order from '../pages/Order'
import Orders from '../pages/Orders'
import CreateOrder from '../pages/CreateOrder'
import Theatres from '../pages/Theatres'
import Cakes from '../pages/Cakes'
import Decorations from '../pages/Decorations'
import Payment from '../pages/Payment'
import Cake from '../pages/Cake'
import Decoration from '../pages/Decoration'
import Theatre from '../pages/Theatre'
import CreateCake from '../pages/CreateCake'
import CreateDecoration from '../pages/CreateDecoration'

function GTRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="payments" element={<Payments />} />
            <Route path='payment/:orderid' element={<Payment />} />
            <Route path='theatres' element={<Theatres />} />
            <Route path='theatres/:thid' element={<Theatre />} />
            <Route path="cakes" element={<Cakes />} />
            <Route path="cakes/:cakeid" element={<Cake />} />
            <Route path="decorations" element={<Decorations />} />
            <Route path='decorations/:decid' element={<Decoration />} />
            <Route path="order/:orderid" element={<Order />} />
            <Route path="orders" element={<Orders />} />
            <Route path="create-order" element={<CreateOrder />} />
            <Route path='create-cake' element={<CreateCake />} />
            <Route path='create-decoration' element={<CreateDecoration />} />
        </Routes>
    )
}

export default GTRoutes